.app-search__input {
	margin-bottom: 0 !important;
	border-radius: 16px;
}

.app-search__button {
	border-radius: 16px;
}

.app-notification__content.search {
    min-width: 100%;
    max-width: 310px;
    width: max-content;
    max-height: calc(100vh - 100px);
}