@media print {
    body {
        background-color: #ccc !important;
    }

    html, head, body, .container, .clearfix {
		margin: 0 !important;
        padding: 0 !important;
	}

    @page {
		margin: 20; padding: 0;
		margin-top: 0.88cm;
		margin-left: 0.47cm;
		margin-right: 0.47cm;
		margin-bottom: 0.47cm;
	}

    .app-header, aside, footer {
		margin: 0 !important;
        padding: 0 !important;
		display: none;
	}

    .app-content {
		margin: 0 !important;
        padding: 0 !important;
        background: none;
	}
}