$border-color: #CED4DA;

.bootstrap-select {
    .dropdown-toggle {
        border: 1px solid $border-color;
    }

    button {
        border: 1px solid $border-color;
    }

    .dropdown-menu {
        width: inherit;
        top: auto !important;
        transform: translate3d(0px, 0px, 0px) !important;
        // max-height: 400px !important;

        .inner {
            // max-height: 350px !important;
            overflow: unset;
            overflow-x: hidden;
        }
    }
}

.app-filter-sidebar {
    .dropdown-menu {
		overflow-x: hidden;
		max-width: 220px;
	}
}