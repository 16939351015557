.ticket {
    .ticket-text {
        background-color: rgba(255, 255, 255, .5);
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .3);
    }

    .ticket-img {
        width: 40px;
        height: 40px;
        border-radius: 64px;
        border: 2px solid #FFF;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .3);

        @media (min-width: 768px) {
            width: 64px;
            height: 64px;
        }

        &.ticket-img-left {
            border-top-left-radius: 0;
        }

        &.ticket-img-right {
            border-top-right-radius: 0;
        }
    }

    .ticker-date {
        font-size: .8em;
    }
}