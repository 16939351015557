.btn-plus {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	position: fixed;
	right: 2vh;
	bottom: 2vh;
	z-index: 9;
}

.btn-table {
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px) {
	.animated-checkbox.checkbox-xl-2 input[type="checkbox"] + .label-text:before {
		font-size: 30px;
	}
}

@media (max-width: 576px) {
	.animated-checkbox.checkbox-xs-2 input[type="checkbox"] + .label-text:before {
		font-size: 30px;
	}

	.img-size-sm { height: 20vh; }
}

form { margin-block-end: 0; }

.form-group.required label:after {
	content:"*";
	color:red;
	font-weight: bold;
}

.jn-filter-footer {
	width: 100%;
	bottom: 0;
	position: absolute;
}

.thumb {
	width: 100vw;
	height: 100vh;
	max-width: 150px;
	max-height: 150px;
}

.jn-btn-camera {
	position: absolute;
	left: 0;
	bottom: 0;
	padding-top: 0;
}

.bg-overlay {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1050;
	visibility: hidden;
	opacity: 0.0;
	overflow: hidden;
	text-align: center;
	transition: 0.3s ease;
}

.bg-overlay-show {
	visibility: visible;
	opacity: 1.0;
}

.bg-overlay img {
	position: fixed;
	margin: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.app-nav__item { line-height: 0; }

table.dataTable>tbody>tr.child ul.dtr-details { display: block !important; }

.thumb {
	width: auto;
	height: 170px;

	&.thumb-sm {
		height: 120px;
	}

	&.thumb-show {
		position: absolute;
		top: -60px;
	}
}

.modal-dialog {
	&.with-thumb {
		top: 60px;
		margin-bottom: 70px;
		height: auto;
		min-height: calc(100vh - 80px);
		margin: 0 auto;
		padding: 10px;
	}
}

// table.dataTable {
// 	width: 100% !important;
// }

label {
	width: max-content;
}

.badge {
	line-height: inherit;
	min-width: 80px;
	box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.3);
}

.input-group-text {
	border: 1px solid rgba(0,0,0,0.2);
}

.btn-help {
	position: absolute;
	top: -5px;
	margin-left: 5px;
}

.btn-card {
	height: 70px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-shadow: inset 0px -1px 2px rgba(0,0,0,0.1);
	border-radius: 8px;
	border: 1px solid rgba(0,0,0,0.1);

	&:hover {
		box-shadow: 0 1px 3px rgba(0,0,0,0.3);
	}

	&:active {
		position: relative;
		top: 2px;
		transform: scale(0.99);
	}

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.badge-custom {
	min-width: 90px;
	padding: 5px;
}

.atender-openChatbot {
    right: 18px !important;
    bottom: 80px !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3) !important;
    z-index: 999 !important;
}

.atender-possoAjudar {
    right: -80px !important;
    bottom: 80px !important;
    padding: 16px !important;
    transition: all 1s ease;
}

#atender-openChatbot:hover + .atender-possoAjudar {
    right: 70px !important;
}
