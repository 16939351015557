.card-module-box {
    .card-header {
        .progress {
            width: 50%;
            margin: 0;
            padding: 0;
            background-color: #EAEAEA;
            text-shadow: 0px .5px 2px rgba(0, 0, 0, 0.9);
        }
    }
}

.card-activity {
    &.game { border: 1px solid rgba(1, 115, 200, 0.6); }
    &.animation { border: 1px solid rgba(169, 5, 106, 0.6); }
    &.song { border: 1px solid rgba(21, 167, 33, 0.6); }
    &.book { border: 1px solid rgba(238, 60, 60, 0.6); }

    .card-header {
        color: #FFF;

        &.game-color { background-color: rgba(18, 125, 205, 0.6) !important; }
        &.animation-color { background-color: rgba(187, 0, 115, 0.6) !important; }
        &.song-color { background-color: rgba(23, 183, 36, 0.6) !important; }
        &.book-color { background-color: rgba(224, 57, 57, 0.6) !important; }

        img {
            width: auto;
            max-height: 30px;

            &.disabled {
                opacity: 0.2;
	            filter: grayscale(100%);
            }
        }
    }

    &.ghost {
        opacity: 0.3;
    }
}