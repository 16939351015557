// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// Variables
@import 'default';
@import 'search';
@import 'field-list-custom';
@import 'progress-bar-custom';
@import 'bootstrap-select-custom';
@import 'footer';
@import 'login';
@import 'swal';
@import 'print';
@import 'print-password';
@import 'print-qrcode';
@import 'ticket';

@import 'utils/btn-circle';
@import 'utils/preview';
@import 'jn-custom';
@import 'like';
@import 'card-custom';
@import 'report-student';