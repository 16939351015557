@media print {
    .box {
        margin: 0;
        padding: 10px;
        width: 544px;
        height: 136px;
        float: left;
        font-family: Verdana;
        font-size: 16px;
        page-break-after: always;
        page-break-inside: avoid;
        border: 1px dashed #CCC;

        .body {
            border-radius:8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 116px;
            border: 1px solid #DDDDDD;
            padding: 10px;

            .name {}
            
            .login {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 5px 0;

                .user {}

                .password {
                    .images {
                        display: inline-block;

                        img {
                            width: auto;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}