.progress {
	box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
	background-color: rgba(0, 0, 0, 0.1);
	height: 30px;

	.progress-bar {
		box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2);
		display: flex;
		justify-content: center;
		align-items: flex-end;

		div {
			width: 40px;
			height: 24px;
			margin-right: 2px;
			background-color: rgba(0, 0, 0, .1);
			border-radius: 50px;
			font-size: 12px;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
			text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
		}
	}
}