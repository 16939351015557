#stage {
	background-color: #000;
}

.stage {
	position: fixed;
	top: 0; right: 0; bottom: 0; left: 0;
	width: 100vw;
	height: 100vh;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	justify-content: center;
	display: none;
	z-index: 1050;
	opacity: 0.0;
	transition: 0.3s ease;

	&.on {
		display: flex;
		opacity: 1.0;
	}
}

.bt-close {
	position: absolute;
	filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.3));
	transition: 0.3s;
	right: 5px; top: 5px;
	z-index: 2;
	cursor: pointer;
	display: none;
	opacity: 0.0;

	@media
		screen and (max-width: 599px) and (orientation: portrait),
		screen and (max-width: 768px) and (orientation: landscape)
	{
		transform: scale(0.7);
	}

	&.on {
		display: flex;
		opacity: 1.0;
	}

	&:hover {
		filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.5)) brightness(1.1);
	}

	&:active {
		filter: none;
		margin-top: 2px;
	}
}

.book-container {
	background-image: url('/assets/img/book/bg.jpg');
	background-repeat: no-repeat;
	width: 768px;
	max-width: 768px;
	height: 100%;
	max-height: 768px;
	display: flex;
	align-items: center;
	justify-content: center;

	.book {
		max-width: 768px;
		max-height: 450px;

		.cover {
			background-image: url('/assets/img/book/cover.jpg');
		}
		.page {
			div {
				max-width: calc(705px / 2);
				width: 100%;
				height: 100%;
				max-height: 450px;
				background-size: cover;
				background-repeat: no-repeat;
				display: flex;
				justify-content: center;
				align-items: center;

				&.R {
					background-position: right;
				}
			}
		}
	}

	.bt-sound {
		width: 50px;
		height: 50px;
		position: absolute;
		margin: 2px;
		mix-blend-mode: exclusion;
		z-index: 2;
		left: 0;
		top: 0;

		&:active {
			margin-top: 3px;
		}
	}
}
