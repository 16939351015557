@media print {
	.box-qrcode {
		width: 272px;
		height: 470px;
		padding:10px;
		float: left;
		font-family: Helvetica;
		font-size: 12px;
		page-break-after: always;
	    page-break-inside: avoid;
	    box-shadow: none;
	    border: 1px dashed #CCC;

		.body {
			border-radius:8px;
			border: 1px solid #DDDDDD;
			height: 445px;

			.texts {
				margin-bottom: 10px;

				.title {
					margin: 30px 0 20px 0;
					text-align: center;
					font-weight: bold;
				}

				ul {
					margin: 0;
					padding: 10px 0 0 20px;
					font-size: 16px;

					li {
						white-space: nowrap;
						width: 210px;
						overflow: hidden;
						padding-right: 10px;
					}
				}
			}

			.qrcode {
				margin-left: 50px;
				padding-top: 40px;
			}
		}
	}
}